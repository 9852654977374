var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-md-12 col-lg-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기계/장비 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c(
                                "q-btn",
                                {
                                  staticClass: "custom-btn",
                                  attrs: {
                                    icon: "help",
                                    color: "deep-purple-6",
                                    "text-color": "white",
                                    align: "center",
                                    round: "",
                                  },
                                },
                                [
                                  _c(
                                    "q-tooltip",
                                    {
                                      attrs: {
                                        anchor: "bottom left",
                                        self: "top left",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustomTop" },
                                        [_vm._v(" 설명 ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustom" },
                                        [
                                          _vm._v(
                                            " o 관리번호는 자동생성됩니다."
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " o 안전검사일은 유효검사기간에 맞춰 설정됩니다."
                                          ),
                                          _c("br"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editable && _vm.isOld
                            ? _c(
                                "q-btn",
                                {
                                  staticClass: "custom-btn",
                                  attrs: {
                                    label: "QR 코드",
                                    icon: "qr_code_scanner",
                                    color: "black",
                                    size: "md",
                                  },
                                },
                                [
                                  _c(
                                    "q-popup-proxy",
                                    [
                                      _c(
                                        "c-card",
                                        {
                                          staticClass: "cardClassDetailForm",
                                          attrs: { title: "QR코드" },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "card-button" },
                                            [
                                              _c("c-btn", {
                                                attrs: {
                                                  label: "",
                                                  icon: "print",
                                                },
                                                on: { btnClicked: _vm.print },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "template",
                                            { slot: "card-detail" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "wrap",
                                                          attrs: {
                                                            action: "#",
                                                            id: "printJS-form",
                                                          },
                                                        },
                                                        [
                                                          _c("vue-qrcode", {
                                                            staticClass:
                                                              "canvas",
                                                            attrs: {
                                                              value:
                                                                _vm.serverName +
                                                                "/sop/mim/hazardEquipment/hazardEquipmentClass?hhmContructFacilityId=" +
                                                                _vm.popupParam
                                                                  .hhmContructFacilityId,
                                                              options: {
                                                                width: 180,
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "facilityStyle",
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "abc",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        "-기계명 : "
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .machineryData
                                                                        .facilityName
                                                                    ) + " "
                                                                  ),
                                                                  _c("br"),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        "-기기번호 : "
                                                                      ) + " "
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .machineryData
                                                                        .deviceNo
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.machineryData,
                                  mappingType: _vm.saveType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.machineryData.hhmContructFacilityId
                            ? _c("c-btn", {
                                attrs: {
                                  label: "삭제",
                                  editable: _vm.editable,
                                  icon: "delete_forever",
                                },
                                on: { btnClicked: _vm.remove },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            changeItem: _vm.changeItem,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.machineryData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "plantCd", $$v)
                            },
                            expression: "machineryData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "관리번호",
                            name: "managerNo",
                          },
                          model: {
                            value: _vm.machineryData.managerNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "managerNo", $$v)
                            },
                            expression: "machineryData.managerNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            afterIcon:
                              _vm.editable && !_vm.disabled
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "add",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "remove",
                                    },
                                  ]
                                : null,
                            editable: _vm.editable,
                            label: "기계/장비 분류",
                            readonly: true,
                            name: "facilityTypeName",
                          },
                          on: { add: _vm.addType, remove: _vm.removeType },
                          model: {
                            value: _vm.machineryData.facilityTypeName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.machineryData,
                                "facilityTypeName",
                                $$v
                              )
                            },
                            expression: "machineryData.facilityTypeName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            plantCd: _vm.machineryData.plantCd,
                            changeItem: _vm.changeItem,
                            multiple: "multiple",
                            label: "공종",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.machineryData.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "processCd", $$v)
                            },
                            expression: "machineryData.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            plantCd: _vm.machineryData.plantCd,
                            changeItem: _vm.changeItem,
                            label: "업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.machineryData.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "vendorCd", $$v)
                            },
                            expression: "machineryData.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "기기번호",
                            name: "deviceNo",
                          },
                          model: {
                            value: _vm.machineryData.deviceNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "deviceNo", $$v)
                            },
                            expression: "machineryData.deviceNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "기계명",
                            name: "facilityName",
                          },
                          model: {
                            value: _vm.machineryData.facilityName,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "facilityName", $$v)
                            },
                            expression: "machineryData.facilityName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            type: "date",
                            editable: _vm.editable,
                            label: "반입일",
                            name: "carryinDate",
                          },
                          model: {
                            value: _vm.machineryData.carryinDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "carryinDate", $$v)
                            },
                            expression: "machineryData.carryinDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            type: "date",
                            range: true,
                            label: "사용기간",
                            name: "useDate",
                          },
                          model: {
                            value: _vm.useDate,
                            callback: function ($$v) {
                              _vm.useDate = $$v
                            },
                            expression: "useDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관리책임자",
                            changeItem: _vm.changeItem,
                            disabled: _vm.disabled,
                            plantCd: _vm.machineryData.plantCd,
                            isVendorOrg: true,
                            userName: "managerUserName",
                            name: "managerUserId",
                          },
                          model: {
                            value: _vm.machineryData.managerUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "managerUserId", $$v)
                            },
                            expression: "machineryData.managerUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable,
                                  suffix: "년",
                                  type: "number",
                                  label: "안전검사유효기간",
                                  name: "checkValidPeriod",
                                },
                                on: { dataChange: _vm.datachangeDate },
                                model: {
                                  value: _vm.machineryData.checkValidPeriod,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.machineryData,
                                      "checkValidPeriod",
                                      $$v
                                    )
                                  },
                                  expression: "machineryData.checkValidPeriod",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.editable,
                                  type: "date",
                                  label: "안전검사일",
                                  start: _vm.start,
                                  end: _vm.end,
                                  name: "safetyDate",
                                },
                                on: { datachange: _vm.datachangeDate },
                                model: {
                                  value: _vm.machineryData.safetyDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.machineryData,
                                      "safetyDate",
                                      $$v
                                    )
                                  },
                                  expression: "machineryData.safetyDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "q-chip",
                                {
                                  class:
                                    _vm.machineryData.validFlagName ===
                                    "유효기간 초과"
                                      ? "blinking"
                                      : null,
                                  attrs: {
                                    color: _vm.setTagColor(
                                      _vm.machineryData.validFlagName ===
                                        "유효기간 초과"
                                        ? "Y"
                                        : _vm.machineryData.validFlagName ===
                                          "입력중"
                                        ? "NE"
                                        : "orange"
                                    ),
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _vm.machineryData.validFlagName ===
                                  "유효기간 초과"
                                    ? _c("q-avatar", {
                                        attrs: {
                                          icon: "alarm",
                                          color: "red",
                                          "text-color": "white",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.machineryData.validFlagName === "입력중"
                                    ? _c("q-avatar", {
                                        attrs: {
                                          icon: "create",
                                          color: "grey-5",
                                          "text-color": "white",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.setTagName(
                                          _vm.machineryData.validFlagName ===
                                            "유효기간 초과"
                                            ? "Y"
                                            : _vm.machineryData.validFlagName
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "실소유자 및 운전원",
                            name: "operationAtor",
                          },
                          model: {
                            value: _vm.machineryData.operationAtor,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "operationAtor", $$v)
                            },
                            expression: "machineryData.operationAtor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "설치보관위치",
                            name: "installLocation",
                          },
                          model: {
                            value: _vm.machineryData.installLocation,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.machineryData,
                                "installLocation",
                                $$v
                              )
                            },
                            expression: "machineryData.installLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "제원",
                            name: "specifications",
                          },
                          model: {
                            value: _vm.machineryData.specifications,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "specifications", $$v)
                            },
                            expression: "machineryData.specifications",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비고",
                            name: "checkAction",
                          },
                          model: {
                            value: _vm.machineryData.checkAction,
                            callback: function ($$v) {
                              _vm.$set(_vm.machineryData, "checkAction", $$v)
                            },
                            expression: "machineryData.checkAction",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "table",
                            attrs: {
                              title: "안전대책 목록",
                              columns: _vm.grid.columns,
                              gridHeight: _vm.grid.height,
                              data: _vm.machineryData
                                .machinerySafetyMeasureModelList,
                              filtering: false,
                              columnSetting: false,
                              isExcelDown: false,
                              usePaging: false,
                              editable: _vm.editable,
                              selection: "multiple",
                              rowKey: "hhmContructFacilityMeasureId",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: {
                                            showLoading: false,
                                            label: "추가",
                                            icon: "add",
                                          },
                                          on: { btnClicked: _vm.addRisk },
                                        })
                                      : _vm._e(),
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.machineryData
                                      .machinerySafetyMeasureModelList.length >
                                      0
                                      ? _c("c-btn", {
                                          attrs: {
                                            showLoading: false,
                                            label: "제외",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRisk },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("br"),
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo,
                            editable: _vm.editable,
                            label: "관련 기계/장비 파일",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                },
                [
                  _c(
                    "c-table",
                    {
                      ref: "table",
                      attrs: {
                        title: "점검 이력 목록",
                        tableId: "hazardEquipmentHis",
                        columns: _vm.grid2.columns,
                        gridHeight: _vm.grid2.height,
                        data: _vm.machineryData.machineryHistoryModelList,
                        usePaging: false,
                        checkClickFlag: false,
                        isExcelDown: false,
                        merge: _vm.grid.merge,
                        selection: "multiple",
                        rowKey: "hhmContructFacilityHistoryId",
                      },
                      on: { linkClick: _vm.linkClick },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          !_vm.isOld
                            ? _c("font", { attrs: { color: "#C10015" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "※ 신규등록시 기본정보 저장 후 점검할 수 있습니다."
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable && _vm.isOld
                                ? _c("c-btn", {
                                    attrs: {
                                      label: "추가",
                                      showLoading: false,
                                      icon: "add",
                                    },
                                    on: { btnClicked: _vm.linkClick },
                                  })
                                : _vm._e(),
                              _vm.editable && _vm.isOld
                                ? _c("c-btn", {
                                    attrs: {
                                      label: "삭제",
                                      showLoading: false,
                                      icon: "remove",
                                    },
                                    on: { btnClicked: _vm.removeItem },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }